import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';
import SitemarkImage from '../img/logo.png'; 


function Copyright() {
  return (
    
    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
      {'Copyright © '}
      {/* <Link color="text.secondary" href="https://mui.com/"> */}
      리앤유 세무회계
      {/* </Link> */}
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'center' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center', // 이미지를 가로로 가운데 정렬
          justifyContent: 'center', // 세로로 가운데 정렬
          gap: 2,
        }}
      >
        <Box component="img" src={SitemarkImage} alt="Sitemark" sx={{ height: 40, width: 180, mr: 2 }} />
        
      
       경기도 화성시 동탄대로 677-12, 효성ICT타워 2층 218호(영천동)<br />
       전화번호 070-4243-7557<br />
       대표자 : 최승리 세무사<br />
      <br /><br /> 
        {/* Copyright 추가 */}
        <Copyright />
      </Box>
    </Container>
  );
}