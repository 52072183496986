// import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import AppAppBar from '../components/AppAppBar';
import Hero from '../components/Hero';
import KakaoMap from '../components/KakaoMap';
import LogoCollection from '../components/LogoCollection';
import Highlights from '../components/Highlights';
import Pricing from '../components/Pricing';
import Features from '../components/Features';
import Testimonials from '../components/Testimonials';
import FAQ from '../components/FAQ';
import Footer from '../components/Footer';
import AppTheme from '../shared-theme/AppTheme';
// import ConsultationForm from '../components/ConsultationForm';
// import TaxRequestForm from '../components/TaxRequestForm';
// import CapitalgaintaxForm from '../components/kj/CapitalgaintaxForm'
import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';



export default function HomePage(props) {
  // Hero 섹션에 대한 참조 생성
  const heroRef = React.useRef(null);
  // FAQ 섹션에 대한 참조 생성
  const faqRef = React.useRef(null);
  // Highlights 섹션에 대한 참조 생성
  const highlightsRef = React.useRef(null);
  // ConsultationForm 섹션에 대한 참조 생성
  const ConsultationFormRef = React.useRef(null);
  // TaxRequestForm 섹션에 대한 참조 생성
  const TaxRequestFormRef = React.useRef(null);
  // CapitalgaintaxForm 섹션에 대한 참조 생성
  const CapitalgaintaxFormRef = React.useRef(null);

  // const heroRef = useRef(null);
  // const faqRef = useRef(null);
  // const highlightsRef = useRef(null);

  const location = useLocation();

  // Hero 섹션으로 스크롤 이동하는 함수
  const scrollToHero = () => {
    heroRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  
  const scrollToFAQ = () => {
    faqRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  
  const scrollToHighlights = () => {
    highlightsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  // ConsultationForm 섹션으로 스크롤 이동하는 함수
  const scrollToConsultationForm = () => {
    ConsultationFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  // ConsultationForm 섹션으로 스크롤 이동하는 함수
  const scrollToTaxRequestForm = () => {
    TaxRequestFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
 // CapitalgaintaxForm 섹션으로 스크롤 이동하는 함수
 const scrollToCapitalgaintaxForm = () => {
    CapitalgaintaxFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // 페이지 로드 시 location.state로 전달된 스크롤 처리
  useEffect(() => {
    if (location.state?.scrollTo) {
      const scrollToTarget = location.state.scrollTo;
      if (scrollToTarget === 'hero') {
        scrollToHero();
      } else if (scrollToTarget === 'faq') {
        scrollToFAQ();
      } else if (scrollToTarget === 'highlights') {
        scrollToHighlights();
      }
    }
  }, [location.state]);

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <AppAppBar 
        scrollToHero={scrollToHero} 
        scrollToFAQ={scrollToFAQ} 
        scrollToHighlights={scrollToHighlights}  
        scrollToConsultationForm={scrollToConsultationForm} 
        scrollToTaxRequestForm={scrollToTaxRequestForm} 
        scrollToCapitalgaintaxForm={scrollToCapitalgaintaxForm}
      /> 
      
      <div ref={heroRef}> {/* Hero 섹션에 ref 추가 */}
        <Hero />
      </div>
      <div>      
        <div ref={highlightsRef}> {/* Highlights 섹션에 ref 추가 */}
          <Highlights />
        </div>
        <Divider />
        <div ref={faqRef}> {/* FAQ 섹션에 ref 추가 */}
          <FAQ />
        </div>
        <Divider />
        <Footer />
      </div>
    </AppTheme>
  );
}
