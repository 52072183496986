import React, { useState } from 'react';
import styled from 'styled-components';


const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8000';  // 환경변수에 주소 없으면 로컬로
const correctionUrl = `${apiUrl}/correction-request`;     

const StyledInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const StyledLabel = styled.label`
  font-weight: bold;
  margin-right: 10px;
  white-space: nowrap; /* 줄바꿈 방지 */
  width: 120px; /* 고정된 너비 */
  text-align: left; /* 왼쪽 정렬 */
`;


const StyledInput = styled.input`
  flex: 1; /* 입력 필드는 남은 공간을 채움 */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const StyledButton = styled.button`
  display: inline-block;
  margin: 0 auto; /* 가운데 정렬 */
  padding: 10px 20px; /* 버튼 내 여백 */
  min-width: 300px; /* 버튼의 최소 가로 크기 */
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const StyledFormContainer = styled.div`
  width: 500px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;

   /* 양도소득세 제목을 가운데 정렬 */
  h2 {
    text-align: center;
  }
    /* 버튼 가운데 정렬 */
  text-align: center;  
  
  /* flexbox 설정 */
  display: flex;
  flex-direction: column;  /* 세로로 배치 */
  align-items: center;     /* 가로 가운데 정렬 */
`;

const StyledMessage = styled.p`
  font-weight: bold;
`;

const GreetingText = styled.h3`
  text-align: center;
  color: #333;
  margin-bottom: 20px;
`;

const InfoText = styled.h4`
  text-align: center;
  color: #333;
  margin-bottom: 20px;
`;




//////////////////////////////////////////// 동의 팝업관련 ////////////////////////////////////////////
const PopupWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
const PopupContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  text-align: center;
`;

const CheckboxContainer = styled.div`
  border: 1px solid #ccc; /* 테두리 추가 */
  padding: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 250px; /* 가로 길이를 200px로 설정 */
  max-width: 100%; /* 화면 크기에 맞게 자동 조정 */
  text-align: left; /* 안에서 왼쪽 정렬 */
  `;

const CheckboxLabel = styled.label`
  margin-left: 10px;
  cursor: pointer;
`;
//////////////////////////////////////////// 동의 팝업관련 ////////////////////////////////////////////


const CapitalgaintaxForm = () => {
  const [formData, setFormData] = useState({
    name1: '',
    jubn: '',
    phone: '',
    taxId: '',
    taxPassword: '',
  });
  const [authMethod, setAuthMethod] = useState('kakao'); // 기본 값: 카카오톡 인증
  const [taxResponseMessage, setTaxResponseMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState([]); // 선택된 과세연도를 저장할 변수

  //////////////////////////////////////////// 동의 팝업관련 ////////////////////////////////////////////
  const [isAgreed, setIsAgreed] = useState(false);  // 개인정보 동의 상태
  const [isPopupOpen, setIsPopupOpen] = useState(false); // 팝업 상태

  const [isAgreed2, setIsAgreed2] = useState(false);  // 제3자정보제공동의 상태
  const [isPopupOpen2, setIsPopupOpen2] = useState(false); // 팝업 상태

  const [isAllAgreed, setIsAllAgreed] = useState(false); // 전체동의 체크 상태


  // 개인정보 이용 동의 팝업 닫기
  const handlePopupClose = () => {
    setIsPopupOpen(false);
    setIsAgreed(true);  // 팝업에서 동의 버튼 클릭 후 체크박스 체크됨
  };
  // 개인정보 이용 동의 체크박스 상태 변경
  const handleAgreeChange = () => {
    setIsAgreed(!isAgreed);
  };
  
  // 제3자정보제공 동의 팝업 닫기
  const handlePopupClose2 = () => {
    setIsPopupOpen2(false);
    setIsAgreed2(true);  // 팝업에서 동의 버튼 클릭 후 체크박스 체크됨
  };
  // 제3자정보제공 동의 체크박스 상태 변경
  const handleAgreeChange2 = () => {
    setIsAgreed2(!isAgreed2);
  };

  // 전체동의
  const handleAllAgreeChange = () => {
    setIsAllAgreed(!isAllAgreed);
    setIsAgreed(!isAllAgreed);
    setIsAgreed2(!isAllAgreed);
  };

  //////////////////////////////////////////// 동의 팝업관련 ////////////////////////////////////////////



  // 현재 시점에서 과세연도를 계산
  const currentYear = new Date().getFullYear();
  const taxYears = [];
  for (let year = currentYear - 5; year <= currentYear; year++) {
    taxYears.push(year);
  }
  // 문자열 리스트로 변환
  const stringSelectedYears = taxYears.map(String); // 숫자를 문자열로 변환

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAuthMethodChange = (e) => {
    setAuthMethod(e.target.value);
    setFormData({
      name1: '',
      jubn: '',
      phone: '',
      taxId: '',
      taxPassword: '',
    }); // 선택 변경 시 필드 초기화
  };

  // const handleYearChange = (e) => {
  //   const year = parseInt(e.target.value, 10); // 문자열을 숫자로 변환
  //   setSelectedYear((prevSelected) => {
  //     if (prevSelected.includes(year)) {
  //       // 이미 선택된 연도라면 제거
  //       return prevSelected.filter((item) => item !== year);
  //     }
  //     // 새로 선택된 연도를 추가
  //     return [...prevSelected, year];
  //   });
  // };
  // 주민번호, 외국인등록번호, 거소번호 검증 함수
  function isValidNumber(number) {
    number = number.replace(/[^0-9]/g, ''); // 숫자만 남기고 나머지 제거
    if (number.length !== 13) return false; // 숫자 13자리인지 확인
    const weights = [2, 3, 4, 5, 6, 7, 8, 9, 2, 3, 4, 5];
    const sum = number
        .slice(0, 12)
        .split('')
        .reduce((acc, digit, idx) => acc + digit * weights[idx], 0);
    let checkDigit = (11 - (sum % 11)) % 10;
    // 외국인등록번호 또는 거소신고번호 처리
    if (['5', '6', '7', '8'].includes(number[6])) {
        checkDigit = (checkDigit + 2) % 10;
      }
    return checkDigit === parseInt(number[12], 10);
  }
  
  const handleTaxRequest = async (e) => {
    e.preventDefault();
    //////////////////////////////////////////// 동의 팝업관련 ////////////////////////////////////////////
    if (!isAgreed) {
      alert('개인정보 이용 동의(필수)를 체크해야 합니다.');
      return;
    }
    if (!isAgreed2) {
      alert('제3자 정보제공 동의(필수)를 체크해야 합니다.');
      return;
    }
    //////////////////////////////////////////// 동의 팝업관련 ////////////////////////////////////////////

    // // 과세연도 선택 여부 확인
    // if (selectedYear.length === 0) {
    //   alert('적어도 하나의 과세연도를 선택해야 합니다.'); // 경고 메시지
    //   return; // 제출 중단
    // }

    setLoading(true);
    setTaxResponseMessage('');

    // 주민등록번호 유효성 검사
    // if (!isValidJubn(formData.jubn)) {
    if (!isValidNumber(formData.jubn)) {
      alert("유효하지 않은 주민등록번호입니다.");
      setLoading(false); // 로딩 상태 종료
      return; // 유효하지 않으면 함수 종료
    }

    // // 선택된 과세연도를 문자열로 변환
    // const stringSelectedYears = selectedYear.map(String);

    // 요청할 URL
    const url = correctionUrl;  // correctionUrl로 통합

    // 전송할 데이터
    const requestData = authMethod === 'taxId' 
      ? {
          taxId: formData.taxId,
          taxPassword: formData.taxPassword,
          phone: formData.phone,
          name1: formData.name1,
          jubn: formData.jubn,
          authMethod: 'taxId',  // authMethod는 taxId
          selectedYears: stringSelectedYears, // 문자열로 변환된 연도 리스트 추가
          aaa : ["양도소득세"],
          saup : "",
        }
      : {
          taxId: "",  // taxId와 비밀번호는 빈 값
          taxPassword: "",
          phone: formData.phone,
          name1: formData.name1,
          jubn: formData.jubn,
          authMethod: 'kakao',  // authMethod는 kakao
          selectedYears: stringSelectedYears, // 문자열로 변환된 연도 리스트 추가
          aaa : ["양도소득세"],
          saup : "",
        };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
      

      const data = await response.json();
      setTaxResponseMessage(data.message || '요청이 완료되었습니다.');
    } catch (error) {
      console.error('Error:', error);
      setTaxResponseMessage('요청 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  return (
    
    <>  

    <br />
    <br />
    <br />
    <br />
    
    <GreetingText>
      양도소득세에 대한 경정청구를 요청하시는 경우 <br />
      아래 폼을 작성해주시면 빠른 시일 내에 확인하여 연락드리겠습니다.
    </GreetingText>

    <StyledFormContainer>
      <h2>양도소득세 경정청구 신청하기</h2>
      <br />
      <form onSubmit={handleTaxRequest}>
        <StyledInputContainer>
          <StyledLabel>인증 방법</StyledLabel>
          <label>
            <input
              type="radio"
              value="kakao"
              checked={authMethod === 'kakao'}
              onChange={handleAuthMethodChange}
            />
            카카오톡 인증 로그인
          </label>
          <label style={{ marginLeft: '10px' }}>
            <input
              type="radio"
              value="taxId"
              checked={authMethod === 'taxId'}
              onChange={handleAuthMethodChange}
            />
            국세청 아이디 로그인
          </label>
        </StyledInputContainer>

        {/* <StyledInputContainer>
          <StyledLabel>과세연도</StyledLabel>
          {taxYears.map((year) => (
            <label key={year} style={{ marginRight: '10px' }}>
              <input
                type="checkbox"
                value={year}
                onChange={handleYearChange}
                checked={selectedYear.includes(year)} // 선택 상태 유지
              />
              {year}
            </label>
          ))}
        </StyledInputContainer> */}

        <StyledInputContainer>
          <StyledLabel>이름</StyledLabel>
          <StyledInput
            type="text"
            name="name1"
            placeholder="홍길동"
            value={formData.name1}
            onChange={handleChange}
            required
          />
        </StyledInputContainer>
        <StyledInputContainer>
          <StyledLabel>주민등록번호</StyledLabel>
          <StyledInput
            type="text"
            name="jubn"
            placeholder="9001011234567"
            value={formData.jubn}
            onChange={handleChange}
            required
          />
        </StyledInputContainer>
        <StyledInputContainer>
          <StyledLabel>핸드폰번호</StyledLabel>
          <StyledInput
            type="text"
            name="phone"
            placeholder="01012345678"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </StyledInputContainer>
       
      
      {authMethod === 'taxId' && (
        <>
          <StyledInputContainer>
            <StyledLabel>국세청 아이디</StyledLabel>
            <StyledInput
              type="text"
              name="taxId"
              placeholder="아이디"
              value={formData.taxId}
              onChange={handleChange}
              required
            />
          </StyledInputContainer>
          <StyledInputContainer>
            <StyledLabel>국세청 비밀번호</StyledLabel>
            <StyledInput
              type="password"
              name="taxPassword"
              placeholder="비밀번호"
              value={formData.taxPassword}
              onChange={handleChange}
              required
            />
          </StyledInputContainer>               
         
        </>
      )}
      {/*//////////////////////////////////////////// 동의 팝업관련 //////////////////////////////////////////// */}
           {/* 전체동의 체크박스 */}
           <div>
              <input
                type="checkbox"
                checked={isAllAgreed}
                onChange={handleAllAgreeChange}
                id="allAgreeCheckbox"
              />
              <CheckboxLabel htmlFor="allAgreeCheckbox">(전체동의) 상기내용을 확인하였습니다.</CheckboxLabel>
            </div>

            {/* 개인정보 이용동의와 제3자 정보제공동의 체크박스 */}
            <CheckboxContainer>
              
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <input
                  type="checkbox"
                  checked={isAgreed}
                  onChange={handleAgreeChange}
                  id="agreeCheckbox"
                  disabled={isAllAgreed} // 전체동의 체크시 비활성화
                />
                <CheckboxLabel htmlFor="agreeCheckbox" onClick={() => setIsPopupOpen(true)}>
              개인정보 이용동의(필수)
            </CheckboxLabel>
          </div>
            {/* 개인정보 동의 팝업 */}
            {isPopupOpen && (
              <PopupWrapper>
                <PopupContent>
                  <h3>제 1장 총칙</h3>
                  <p>제1조 (목 적)<br />
                  이 약관은 리앤유세무회계(이하 “회사”라 합니다)가 온라인을 통해 제공하는 인스턴트 메신저 서비스 및 이에 부수하는 다양한 플랫폼 서비스 (이하 “서비스”라 합니다)의 이용에 대한 회사와 서비스 이용자의 권리ㆍ의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.<br /><br />
                  제2조 (약관의 효력 및 변경)<br />
                  1) 이 약관은 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공지함으로써 효력이 발생합니다.<br />
                  2) 회사는 약관의 규제에 관한 법률, 개인정보보호법, 위치정보보호법, 전자상거래 등에서의 소비자보호에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관계법령에 위배되지 않는 범위내에서 이 약관을 개정할 수 있습니다.<br />
                  3) 회사는 약관을 개정할 경우 변경사항을 시행일자 15일전부터 서비스 공지사항에서 공지 또는 통지하는 것을 원칙으로 하며, 회원의 권리 또는 의무에 관한 중요한 규정의 변경은 최소한 30일전에 공지하고 개정약관을 회원이 등록한 메일, SMS 등으로 발송하여 통지합니다.<br />
                  4) 회사가 제3항에 따라 개정약관을 공지 또는 통지하면서 회원에게 약관 변경 적용일까지 거부의사를 표시 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 공지 또는 통지하였음에도 불구하고 회원이 명시적 으로 거부의사를 표시하지 아니하는 경우 회원이 개정약관에 동의한 것으로 봅니다. 회원이 개정약관에 대해 동의하지 않는 경우 회사 또는 회원은 서비스 이용계약을 해지할 수 있습니다.</p>

                  <StyledButton type="submit" onClick={handlePopupClose}>동의 후 진행하기</StyledButton>
                  {/* <button onClick={handlePopupClose}>동의 후 진행하기</button> */}
                </PopupContent>
              </PopupWrapper>
            )}

              <div>
                <input
                  type="checkbox"
                  checked={isAgreed2}
                  onChange={handleAgreeChange2}
                  id="agreeCheckbox2"
                  disabled={isAllAgreed} // 전체동의 체크시 비활성화
                />
                <CheckboxLabel htmlFor="agreeCheckbox2" onClick={() => setIsPopupOpen2(true)}>
            제3자 정보제공동의(필수)
            </CheckboxLabel>
          </div>
            {/* 제3자 정보제공 동의 팝업 */}
            {isPopupOpen2 && (
              <PopupWrapper>
                <PopupContent>
                  <h3>제 1장 총칙</h3>
                  <p>제1조 (목 적)<br />
                  이 약관은 리앤유 세무회계(이하 “회사”라 합니다)가 온라인을 통해 제공하는 인스턴트 메신저 서비스 및 이에 부수하는 다양한 플랫폼 서비스 (이하 “서비스”라 합니다)의 이용에 대한 회사와 서비스 이용자의 권리ㆍ의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.<br /><br />
                  제2조 (약관의 효력 및 변경)<br />
                  1) 이 약관은 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공지함으로써 효력이 발생합니다.<br />
                  2) 회사는 약관의 규제에 관한 법률, 개인정보보호법, 위치정보보호법, 전자상거래 등에서의 소비자보호에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관계법령에 위배되지 않는 범위내에서 이 약관을 개정할 수 있습니다.<br />
                  3)회사는 약관을 개정할 경우 변경사항을 시행일자 15일전부터 서비스 공지사항에서 공지 또는 통지하는 것을 원칙으로 하며, 회원의 권리 또는 의무에 관한 중요한 규정의 변경은 최소한 30일전에 공지하고 개정약관을 회원이 등록한 메일, SMS 등으로 발송하여 통지합니다.<br />
                  4)회사가 제3항에 따라 개정약관을 공지 또는 통지하면서 회원에게 약관 변경 적용일까지 거부의사를 표시 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 공지 또는 통지하였음에도 불구하고 회원이 명시적 으로 거부의사를 표시하지 아니하는 경우 회원이 개정약관에 동의한 것으로 봅니다. 회원이 개정약관에 대해 동의하지 않는 경우 회사 또는 회원은 서비스 이용계약을 해지할 수 있습니다.</p>

                  <StyledButton type="submit" onClick={handlePopupClose2}>동의 후 진행하기</StyledButton>
                  {/* <button onClick={handlePopupClose}>동의 후 진행하기</button> */}
                </PopupContent>
              </PopupWrapper>
              )}
            </CheckboxContainer>

          
            <br />
            {/*//////////////////////////////////////////// 동의 팝업관련 //////////////////////////////////////////// */}

       
        {authMethod === 'kakao' && (
          <>  
            <StyledButton type="submit" disabled={loading} onClick={handleTaxRequest}>
                {loading ? '처리 중...' : '카카오톡 인증하고 국세청 신고자료 보내기'}
            </StyledButton>
          </>
        )}
        {authMethod === 'taxId' && (
          <>
            <StyledButton type="submit" disabled={loading} onClick={handleTaxRequest}>
              {loading ? '처리 중...' : '국세청 아이디로 로그인하고 신고자료 보내기'}
            </StyledButton>
          </>
        )}        

        <br />
        <br />
        국세청에서 자료를 받아오는데 시간이 소요됩니다.<br />
        아래에 완료메시지가 뜰 때까지 기다려주세요.
      </form>
      {/* {taxResponseMessage && <p>{taxResponseMessage}</p>} */}
      {taxResponseMessage && <StyledMessage>{taxResponseMessage}</StyledMessage>}

    </StyledFormContainer>

    <InfoText>
    1. 양도소득세 경정청구 절차<br />
    <br />
    - 정보입력 <br />
    - 카톡인증기반 전체자료취합 <br />
    - 세무사전송 <br />
    - 세무사분석 <br />
    - 통보(가능/불가능) <br />
    - 계약서작성 <br />
    - 진행<br />
    <br />
    <br />
    2. 경정청구란? <br />
    위험성 
    <br />자료수집범위, 기간 법적효력 명시
    </InfoText>
    </>
  );
};

export default CapitalgaintaxForm;
