import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import KJ from './pages/Kj';  // KJ 페이지를 임포트
import Kj_capital from './pages/Kj_capital'; 
import Kj_Incometax from './pages/Kj_Incometax'; 
import Kj_VATtax from './pages/Kj_VATtax'; 
import Taxplan from './pages/Page_Taxplan'; 
import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/Kj" element={<KJ />} />  {/* /kj 경로에 KJPage 컴포넌트를 렌더링 */}
        <Route path="/Kj_capital" element={<Kj_capital />} /> 
        <Route path="/Kj_Incometax" element={<Kj_Incometax />} /> 
        <Route path="/Kj_VATtax" element={<Kj_VATtax />} /> 
        <Route path="/Taxplan" element={<Taxplan />} />  

      </Routes>
    </Router>
  );
}

export default App;
