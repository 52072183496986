import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import AppAppBar from '../components/AppAppBar';
import Hero from '../components/Hero';
import KakaoMap from '../components/KakaoMap';
import LogoCollection from '../components/LogoCollection';
import Highlights from '../components/Highlights';
import Pricing from '../components/Pricing';
import Features from '../components/Features';
import Testimonials from '../components/Testimonials';
import FAQ from '../components/FAQ';
import Footer from '../components/Footer';
import AppTheme from '../shared-theme/AppTheme';
import ConsultationForm from '../components/ConsultationForm';
import TaxRequestForm from '../components/TaxRequestForm';
// import CapitalgaintaxForm from '../components/CapitalgaintaxForm'
import VATtaxForm from '../components/kj/VATtaxForm'

export default function HomePage(props) {
  // VATtaxForm 섹션에 대한 참조 생성
  const VATtaxFormRef = React.useRef(null);
 // VATtaxForm 섹션으로 스크롤 이동하는 함수
 const scrollToVATtaxForm = () => {
  VATtaxFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Hero 섹션에 대한 참조 생성
  const heroRef = React.useRef(null);
  // FAQ 섹션에 대한 참조 생성
  const faqRef = React.useRef(null);
  // Highlights 섹션에 대한 참조 생성
  const highlightsRef = React.useRef(null);
  const location = useLocation();

  // Hero 섹션으로 스크롤 이동하는 함수
  const scrollToHero = () => {
    heroRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };  
  const scrollToFAQ = () => {
    faqRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };  
  const scrollToHighlights = () => {
    highlightsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };




  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <AppAppBar  
          scrollToHero={scrollToHero}
          scrollToFAQ={scrollToFAQ}
          scrollToHighlights={scrollToHighlights}
          scrollToVATtaxForm={scrollToVATtaxForm}/>
        <div>
        <div ref={VATtaxFormRef}>
          <VATtaxForm />
        </div>
        <Divider />
        {/* <div ref={faqRef}> 
          <FAQ />
        </div>
        <Divider /> */}
        <Footer />
      </div>
    </AppTheme>
  );
}
