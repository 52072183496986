
import React, { useState, useEffect } from 'react';
import '../App.css';

// 환경변수로 서버 주소 관리
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8000'; 
const postsUrl = `${apiUrl}/posts`;



export default function Taxplan(props) {
    const [posts, setPosts] = useState([]);  // 여러 개의 포스트를 담을 배열 상태
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // 백엔드에서 제목과 내용을 받아오기
    useEffect(() => {
      const fetchPostData = async () => {
        try {
          const response = await fetch(postsUrl);
          if (!response.ok) {
            throw new Error('데이터를 가져오는 데 실패했습니다.');            
          }
          const data = await response.json();
          setPosts(data);  // 받아온 데이터로 posts 상태 업데이트
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      };
    // 빈 배열을 전달하여 컴포넌트가 처음 마운트될 때만 실행
      fetchPostData();
    }, []); 
    // 로딩 중 또는 오류 처리
    if (loading) {return <div>로딩 중...</div>;}
    if (error) {return <div>오류 발생: {error}</div>;}
    // 줄 바꿈을 <br />로 변경하는 함수
    const formatText = (text) => {
      return text.split('\n').map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ));
    };
    return (
      <div className="App">
        <header className="App-header">
          {/* <p style={{ fontWeight: 'bold', color: 'rgb(130, 180, 255)', textAlign: 'left', marginLeft: '-95px', marginTop: '35px', fontSize: '25px'}}> */}
          <p style={{ fontWeight: 'bold', color: 'rgb(130, 180, 255)', textAlign: 'left', fontSize: '25px'}}>
            <br /><br />
            납세자분들 개별 상황에 맞게,<br />
            세금신고 전반에 관한 사항들을 관리해드립니다.
          </p>
          {/* posts 배열을 순회하면서 제목과 내용을 렌더링 */}
          {posts.map((post, index) => (
            <div key={index} className="post">
              <h3>{post.title}</h3>
              <p>{formatText(post.content)}</p>
            </div>
          ))}
        </header>
      </div>
    );
  



}
