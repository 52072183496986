import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import AppTheme from '../shared-theme/AppTheme';
import CapitalgaintaxForm from '../components/kj/CapitalgaintaxForm'
import Kj_Hero from '../components/kj/Kj_Hero'


export default function HomePage(props) {
//   // CapitalgaintaxForm 섹션에 대한 참조 생성
//   const CapitalgaintaxFormRef = React.useRef(null);
   // kj_hero Form 섹션에 대한 참조 생성
   const Kj_HeroRef = React.useRef(null);

  // Hero 섹션에 대한 참조 생성
  const heroRef = React.useRef(null);
  // FAQ 섹션에 대한 참조 생성
  const faqRef = React.useRef(null);
  // Highlights 섹션에 대한 참조 생성
  const highlightsRef = React.useRef(null);
  const location = useLocation();

  // Hero 섹션으로 스크롤 이동하는 함수
  const scrollToHero = () => {
    heroRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };  
  const scrollToFAQ = () => {
    faqRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };  
  const scrollToHighlights = () => {
    highlightsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <AppTheme {...props}>
      <CssBaseline enableColorScheme />
      <AppAppBar 
        scrollToHero={scrollToHero}
        scrollToFAQ={scrollToFAQ}
        scrollToHighlights={scrollToHighlights}
      />     
      <div>
        <div ref={Kj_HeroRef}>
          <Kj_Hero />
        </div>
        <Divider />
        <Footer />
      </div>
    </AppTheme>
  );
}
