// import * as React from 'react';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // useLocation 훅 추가
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Sitemark from './SitemarkIcon';
import ColorModeIconDropdown from '../shared-theme/ColorModeIconDropdown';
import SitemarkImage from '../img/logo.png'; 
import KakaoImage  from '../img/kakao.png'; 
import NaverImage  from '../img/naver.png'; 
import KJImage  from '../img/kjimage.png'; 

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: (theme.vars || theme).palette.divider,
  backgroundColor: theme.vars
    ? `rgba(${theme.vars.palette.background.defaultChannel} / 0.4)`
    : alpha(theme.palette.background.default, 0.4),
  boxShadow: (theme.vars || theme).shadows[1],
  padding: '8px 12px',
}));

export default function AppAppBar({ scrollToHero, scrollToFAQ, scrollToHighlights, scrollToConsultationForm, scrollToTaxRequestForm,scrollToCapitalgaintaxForm }) {

  useEffect(() => {
    console.log('scrollToHero:', scrollToHero);
    console.log('scrollToFAQ:', scrollToFAQ);
    console.log('scrollToHighlights:', scrollToHighlights);
  }, [scrollToHero, scrollToFAQ, scrollToHighlights]);

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();  // navigate 훅을 사용하여 페이지 이동을 처리
  const location = useLocation(); // 현재 경로 가져오기

  // 특정 경로에서 경정청구신청 이미지를 숨기기
  const hideImageOnPaths = ['/Kj_capital','/Kj','/Kj_Incometax','/Kj_VATtax']; // 숨기고 싶은 주소를 배열로 정의

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };


  // 공통 스크롤 함수
  const handleScrollOrNavigate = (scrollTarget, targetName) => {
    if (!scrollTarget) {
      console.error("scrollTarget is undefined");
      return;
    }

    if (location.pathname === '/') {
      scrollTarget();
    } else {
      navigate('/', { state: { scrollTo: targetName } });
    }
  };
  
  const handleKjClick = () => {
    navigate('/Kj');  // "경정청구" 버튼 클릭 시 /kj 경로로 이동
  };

  const handleTaxplanClick = () => {
    navigate('/Taxplan');  // 
  };

  const handleLeeandyouClick = () => {
    navigate('/');  // 리앤유세무회계 클릭시 메인으로 이동
  };

  return (
    <AppBar
      position="fixed"
      enableColorOnDark
      sx={{
        boxShadow: 0,
        bgcolor: 'transparent',
        backgroundImage: 'none',
        mt: 'calc(var(--template-frame-height, 0px) + 28px)',
      }}
    >
      <Container maxWidth="lg">
        <StyledToolbar variant="dense" disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
             {/* <Sitemark /> */}
            {/* Sitemark 대신 이미지 파일을 추가 */}
            <Box component="img" src={SitemarkImage} alt="Sitemark" sx={{ height: 40, width:180, mr: 2 }} onClick={handleLeeandyouClick} />
    
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Button variant="text" color="info" size="small" onClick={() => handleScrollOrNavigate(scrollToHero, 'hero')}>
                소개
              </Button>
              <Button variant="text" color="info" size="small"  onClick={() => handleScrollOrNavigate(scrollToHighlights, 'highlights')}>
                하는 일
              </Button>
              
              {/* <Button variant="text" color="info" size="small" onClick={scrollToConsultationForm}>
                기장상담
              </Button> */}
              {/* <Button variant="text" color="info" size="small" onClick={scrollToTaxRequestForm}>
                경정청구
              </Button> */}
              <Button variant="text" color="info" size="small" onClick={() => handleScrollOrNavigate(scrollToFAQ, 'faq')}>
                자주묻는질문
              </Button>
            
              {/* <Button variant="text" color="info" size="small" sx={{ minWidth: 0 }}>
                FAQ
              </Button>  */}
              {/* <Button variant="text" color="info" size="small" onClick={() => window.open('https://pf.kakao.com/_zcRlj', '_blank')}>
                카카오톡추가               
              </Button>
              <Button variant="text" color="info" size="small" sx={{ minWidth: 0 }} onClick={() => window.open('https://blog.naver.com/taxtax12', '_blank')}>
                블로그
              </Button> */}
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 1,
              alignItems: 'center',
            }}
          >
            {/* <Button color="primary" variant="text" size="small">
              로그인
            </Button> */}
            {/* <Button color="primary" variant="contained" size="small" onClick={scrollToCapitalgaintaxForm}> */}
            <Button color="primary" variant="contained" size="small" onClick={handleTaxplanClick}>
              세무일정
            </Button>
            <Button color="primary" variant="contained" size="small" onClick={handleKjClick}>
              경정청구
            </Button>
            {/* <ColorModeIconDropdown /> */}
          </Box>


          {/* 모바일? */}
          <Box sx={{ display: { xs: 'flex', md: 'none' }, gap: 1 }} onClick={handleTaxplanClick}>
            {/* <ColorModeIconDropdown size="medium" /> */}
            <Button color="primary" variant="contained" fullWidth>
                   세무일정
            </Button>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, gap: 1 }} onClick={handleKjClick}>
            {/* <ColorModeIconDropdown size="medium" /> */}
            <Button color="primary" variant="contained" fullWidth>
                   경정청구
            </Button>
          </Box>
        </StyledToolbar>
      </Container>



      {/* 카카오톡 - 화면 오른쪽 밑에 이미지 추가 */}
      <Box
        sx={{
          // 위치
          position: 'fixed',
          bottom: 100,
          right: 20,
        }}
      >
        <a href="http://pf.kakao.com/_zcRlj/chat" target="_blank" rel="noopener noreferrer">
          <Box
            component="img"
            src={KakaoImage}  // KakaoImage로 변경
            alt="Kakao"
            sx={{
              height: 60,
              width: 60,
              cursor: 'pointer',
              borderRadius: '50%',  // 이미지 테두리를 둥글게 설정
              border: '3px solid #ccc',  // 테두리 색상
            }}
          />
        </a>
      </Box>
      {/* 블로그 - 화면 오른쪽 밑에 이미지 추가 */}
      <Box
        sx={{
          // 위치
          position: 'fixed',
          bottom: 170,
          right: 20,
        }}
      >
        <a href="https://blog.naver.com/taxtax12" target="_blank" rel="noopener noreferrer">
          <Box
            component="img"
            src={NaverImage}  // KakaoImage로 변경
            alt="Naver"
            sx={{
              height: 60,
              width: 60,
              cursor: 'pointer',
              borderRadius: '50%',  // 이미지 테두리를 둥글게 설정
              border: '3px solid #ccc',  // 테두리 색상
            }}
          />
        </a>
      </Box>

      {/* 경정청구- 화면 오른쪽 밑에 이미지 추가  
      {!hideImageOnPaths.includes(location.pathname) && ( // 경로 조건부 렌더링
        <Box
        sx={{
          position: 'fixed',
          bottom: 16,
          left: '50%',
          transform: 'translateX(-50%)', // 화면 가로 가운데 정렬
        }}
        >
        <Box
          component="img"
          src={KJImage}
          alt="KJImage"
          onClick={handleKjClick} // 이미지 클릭 시 /Kj 경로로 이동
          sx={{
            cursor: 'pointer',
            borderRadius: '10px', // 테두리를 둥글게 설정
            border: '2px solid #ccc', // 테두리 추가
          }}
        />
      </Box>
      )} */}

      {/* 경정청구 - 화면 오른쪽 밑에 박스 추가 */}
      {!hideImageOnPaths.includes(location.pathname) && ( // 경로 조건부 렌더링
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            left: '50%',
            transform: 'translateX(-50%)', // 화면 가로 가운데 정렬
          }}
        >
          <Box
            onClick={handleKjClick} // 박스 클릭 시 /Kj 경로로 이동
            sx={{
              cursor: 'pointer',
              // backgroundColor: '#007BFF', // 파란색 배경
              backgroundColor: '#213BFB',
              color: '#FFFFFF', // 흰색 글자
              borderRadius: '10px', // 테두리를 둥글게 설정
              border: '2px solid #FFFFFF', // 테두리 추가
              padding: '10px 20px', // 내부 여백
              textAlign: 'center', // 텍스트 가운데 정렬
              fontSize: '30px', // 글자 크기
              fontWeight: 'bold', // 글자 굵게
              width: '300px', // 박스 너비
            }}
          >
            경정청구 신청하기
          </Box>
        </Box>
      )}


    </AppBar>
    
  );
}
